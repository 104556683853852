
<template>
  <div>
    <b-table-simple striped responsive="sm">
      <b-thead>
        <b-th
          >{{
            $getVisibleNames(
              "evaluations.observationachievement",
              false,
              "Niveles de Logro"
            )
          }}
        </b-th>

        <b-th>Descripción</b-th>
        <b-th style="width: 80px">{{
          RubricPreset && RubricPreset.show_percentage_sign
            ? "% Logro"
            : "Puntaje"
        }}</b-th>
        <b-th v-if="allows_crud" style="width: 30px" class="text-center">
          <button-add
            v-if="allows_crud"
            :title="`Crear ${$getVisibleNames(
              'evaluations.observationachievement',
              false,
              'Nivel de Logro'
            )}`"
            v-b-tooltip.v-secondary.left.noninteractive="
              `Crear ${$getVisibleNames(
                'evaluations.observationachievement',
                false,
                'Nivel de Logro'
              )}`
            "
            @click="
              $bvModal.show(
                `created-rubric_preset_unit-modal-${RubricPreset.id}`
              )
            "
            size="sm"
          >
          </button-add>
        </b-th>
      </b-thead>
      <b-tbody>
        <template v-for="rubric_preset_unit in rubricPresetList">
          <b-tr :key="`${RubricPreset.id}-${rubric_preset_unit.id}`">
            <b-td class="pl-3 text-justify align-text-top">
              {{ rubric_preset_unit.title }}
            </b-td>
            <b-td>
              <div
                class="rich-text-content"
                v-html="rubric_preset_unit.description"
              ></div>
            </b-td>
            <b-td>
              {{ rubric_preset_unit.score }}
            </b-td>
            <b-td v-if="allows_crud">
              <div class="d-flex">
                <button-edit
                  @click="
                    $bvModal.show(
                      `edit-rubric_preset_unit-modal-${rubric_preset_unit.id}`
                    )
                  "
                  v-b-tooltip.v-secondary.noninteractive="
                    `Editar ${$getVisibleNames(
                      'evaluations.observationachievement',
                      false,
                      'Niveles de Logro'
                    )}`
                  "
                ></button-edit>
                <button-delete
                  @click="askForDeleteRubricPresetUnit(rubric_preset_unit.id)"
                  v-b-tooltip.v-secondary.noninteractive="
                    `Eliminar ${$getVisibleNames(
                      'evaluations.observationachievement',
                      false,
                      'Niveles de Logro'
                    )}`
                  "
                ></button-delete>
              </div>
              <b-modal
                :id="`edit-rubric_preset_unit-modal-${rubric_preset_unit.id}`"
                :title="`Editar ${$getVisibleNames(
                  'evaluations.observationachievement',
                  false,
                  'Niveles de Logro'
                )}`"
                size="md"
                hide-footer
              >
                <RubricPresetUnitForm
                  :RubricPreset="RubricPreset"
                  :RubricPresetUnit="rubric_preset_unit"
                  @updated="slotUpdatedRubricPresetUnit"
                ></RubricPresetUnitForm>
              </b-modal>
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
      <template v-if="rubricPresetList.length == 0">
        <tbody>
          <tr>
            <td :colspan="allows_crud ? 4 : 2">No hay datos que mostrar.</td>
          </tr>
        </tbody>
      </template>
    </b-table-simple>
    <b-modal
      :id="`created-rubric_preset_unit-modal-${RubricPreset.id}`"
      :title="`Editar ${$getVisibleNames(
        'evaluations.observationachievement',
        false,
        'Niveles de Logro'
      )}`"
      size="md"
      hide-footer
    >
      <RubricPresetUnitForm
        :RubricPreset="RubricPreset"
        @created="
          $bvModal.hide(`created-rubric_preset_unit-modal-${RubricPreset.id}`)
        "
      ></RubricPresetUnitForm>
    </b-modal>
  </div>
</template>

<script>
import { toast } from "@/utils/utils";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "RubricPresetUnit",
  components: {
    RubricPresetUnitForm: () =>
      import(
        "@/components/new-evaluations/Rubrics/RubricsPreset/RubricPresetUnitForm"
      ),
  },
  props: {
    RubricPreset: {
      type: Object,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      rubric_preset_units: names.RUBRIC_PRESET_UNITS,
    }),
    rubricPresetList() {
      return [
        ...this.rubric_preset_units.filter(
          (x) => this.RubricPreset.id == x.rubric_preset
        ),
      ].sort((a, b) => b.score - a.score);
    },
  },
  methods: {
    slotUpdatedRubricPresetUnit(rubric_preset_unit) {
      this.$bvModal.hide(
        `edit-rubric_preset_unit-modal-${rubric_preset_unit.id}`
      );
    },
    askForDeleteRubricPresetUnit(rubric_preset_unit_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "evaluations.observationachievement",
          false,
          "Niveles de Logro"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_RUBRIC_PRESET_UNIT, rubric_preset_unit_id)
            .then(() => {
              toast(
                String(
                  this.$getVisibleNames(
                    "evaluations.observationachievement",
                    false,
                    "Niveles de Logro"
                  ) + " eliminado."
                )
              );
            });
        }
      });
    },
  },
  created() {},
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
td,
table {
  padding: 0;
  width: -webkit-fill-available;
}
th {
  padding: 0;
  background: var(--primary-color) !important;
  text-align: center !important;
}
</style>